import React, { FC } from 'react';
import { GridProps } from '../Grid/types';
import { classes } from './NewGrid.st.css';

export const NewGrid: FC<GridProps> = ({ children }) => {
  return (
    <ul data-hook="Grid-container" className={classes.gridContainer}>
      {children}
    </ul>
  );
};
